.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
  vertical-align: top;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #333333;
}

.App-link {
  color: #ffffff;
  font-size: calc(20px + 2vmin);
  background-color: #006f3a;
  margin-left: 20%;
  margin-right: 20%;
  padding-top: 4vh;
  padding-bottom: 4vh;
  border-radius: 5px;
  margin-bottom: 2vh;
  width: 60%;
}

.App-link:hover {
  color: #ffffff;
  text-decoration: underline;
  background-color: #00522A;
}

.iframe{
  height: 100vh;
  overflow:hidden;
  margin-top:50px;
}